<template>
  <div class="position">
    <div class="left-top">
      <span style="font-size: 18px; font-weight: 400; color: #333"
        >固定区域管理</span
      >
      <el-divider></el-divider>
    </div>
    <p style="color: #ccc; padding-bottom: 20px">
      应用于内容底部信息展示区域
    </p>
    <el-radio @change="choseone" v-model="radio" label="1">帮助中心</el-radio>
    <el-radio @change="choseone" v-model="radio" label="2">文章</el-radio>
    <el-radio @change="choseone" v-model="radio" label="3">社区</el-radio>
    <div class="content"><i>*</i><span>固定区域内容:</span></div>
    <div ref="editor" id="div1"></div>
    <footer>
      <el-button type="primary" @click="submit()"> 提交 </el-button>
      <el-button type="info" @click="reset()"> 重置 </el-button>
    </footer>
  </div>
</template>

<script>
import E from "wangeditor";
export default {
  data() {
    return {
      html: "",
      ed: "",
      Content: "",
      submitdata: {
        Type: 1,
      },
      radio: "1",
    };
  },
  created() {},
  mounted() {
    this.init_wangeditor();
    this.getdata(1);
  },
  methods: {
    //单选
    choseone() {
      console.log(this.radio);
      this.getdata(this.radio);
      this.submitdata.Type = this.radio;
    }, 
    //富文本
    init_wangeditor() {
      let _this = this;
      const editor = new E("#div1");
      // this.editor = new E(this.$refs.editor);
      this.ed = editor;
      editor.config.zIndex = 1;
      editor.config.height = 400;
      editor.config.placeholder = "输入正文";
      editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "svg"];
      editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 2M
      editor.config.uploadImgServer =
        "https://file.1renshi.com/FileHandler.ashx?module=article&type=image";
      editor.config.showLinkImg = false;
      editor.config.uploadFileName = "file[]";
      editor.config.uploadImgParams = {
        type: "image",
      };
      editor.config.uploadImgHooks = {
        customInsert: function (insertImg, result, editor2) {
          uploadResult(result);
          // var url = fileUrl + result.data.success[0].url;
          insertImg(_this.uploadimg);
        },
      };
      function uploadResult(res) {
        if (res.isSucc) {
          _this.uploadimg = res.success_list[0];
        }
      }
      editor.config.onchange = (html) => {
        _this.html = html;
      };
      editor.config.menus = [
        "head", // 标题
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "justify", // 对齐方式
        "splitLine", //分割线
        "image", // 插入图片
        "link", // 插入链接
        "undo", // 撤销
      ];
      editor.create();
    },
    getdata(num) {
      this.$get(`${this.$api.GetFixedContentByType}?Type=${num}`).then(
        (res) => {
          if (res.Data) {
            console.log('yes');
            this.Content = res.Data.Content;
            this.submitdata = res.Data;
            this.ed.txt.html(this.Content);
          } else{
            console.log('no');
            this.Content = "";
            this.submitdata = {};
            this.ed.txt.html("");
          }
        }
      );
    },
    submit() {
      let rule = this.html.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "");
      console.log(rule);
      let a = false;
      if (rule == "") {
        a = true;
      }
      let regu = "^[ ]+$";
      let re = new RegExp(regu);
      if (re.test(rule)) {
        a = true;
      }
      console.log(this.Content, a);
      if (a) {
        // this.iscontent = true;
        this.$message({
          message: "请填写内容",
          type: "warning",
          duration: 1000,
        });
        return;
      }
      this.submitdata.Content = this.html;
      this.submitdata.Type = this.radio;
      console.log(this.submitdata);
      this.$post(this.$api.EditFixedContent, this.submitdata, null).then(
        (res) => {
          if (res.IsSuccess) {
            this.$message({
              message: "提交成功",
              type: "success",
              duration: 1000,
            });
          }
        }
      );
    },
    reset() {
      this.submitdata.Content = "";
      this.html = "";
      this.ed.txt.html("");
    },
  },
};
</script>

<style lang="stylus" scoped>
.position {
  background: #fff;
  height: 100%;
  padding: 10px 10px;
  border-radius: 10px;

  .content {
    padding: 20px 0;

    i {
      color: red;
      padding-right: 10px;
    }
  }

  footer {
    button {
      margin: 20px 30px;
    }
  }
}
</style>
